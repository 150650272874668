  .dialog {
    div[aria-describedby="alert-dialog-description"] {
      border-radius: 15px;
      max-height: 277px;
      max-width: 303px;
    }

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #333333;
      font-family: 'Poppins';
    }

    .actions{
      display: flex;
      justify-content: center;
      padding-bottom: 22px;
    }
      .actionButton {
        height: 50px;
        width: 90%;
        font-size: 14px;
        font-style: normal;
        font-weight: 100;
        text-transform: none;
        font-family: 'Poppins';
        background: #5B84BA;
        border-radius: 8px;
        color: #fff;
      }

    .dialogContent {
      font-size: 12px;
      color: #767A8E;
    }

      @media screen and (max-width: 300px) {
        .actionButton {
          height: 40px;
          width: 80px;
          font-size: 10px;
        }
    }
  }
