.dialog {
  div[aria-describedby="dev-support"] {
    border-radius: 15px;
  }

  .title {
    align-items: center;
    color: #333;
    display: flex;
    font-weight: 700;
  }
  .subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
    margin-left: 5px;
  }

  .listItem span:nth-child(2) {
    @extend .subtitle;
    font-size: 12px;
    line-height: 14px;
    svg{
      color: #5B84BA;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    padding: 0 16px 16px;

    .actionButton {
      border-radius: 12px !important;
      height: 50px;
      width: 120px;
      font-size: 14px;
      font-style: normal;
      font-weight: 100;
    }

    @media screen and (max-width: 300px) {
      .actionButton {
        height: 40px;
        width: 80px;
      }
    }

    .actionButton:first-child {
      border: 2px solid #5B84BA !important;
      color: #5B84BA !important;
    }

    .actionButton:last-child {
      background-color: #5B84BA !important;
      color: #fff;
    }

    .placeholder {
      width: 50%;
      height: 12px;
    }
  }
}