.splashContainer {
  background-color: rgb(0 0 0 / 50%);
  display: flex;
  height: 100%;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;

  .mobileTip {
    left: 50%;
    position: absolute;
    text-align: left;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    .mobileTipHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    div:first-child {
      font-size: 14px;
      font-weight: 500;
      color: #333;
      font-family: 'Poppins', 'sans-serif';
    }
  }

  .closeButton {
    background-color: #fff;
    border: 1px solid #5B84BA;
    border-radius: 8px;
    color: #5B84BA;
    width: 40px;
    height: 35px;
    svg {
      font-size: 1.5rem;
    }
  }

  .list {
    padding: 0 0 0 15px;
  }
}
