.title {
  align-items: center;
  color: #333c6b;
  display: flex;
  width: 100%;
  font-family: 'Poppins';
}

.titleIcon {
  align-items: center;
  background: #333c6b;
  border-radius: 50px;
  color: white !important;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-bottom: 8px;
  width: 50px;
}

.titleText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  margin-left: 5px;
}

.content {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #767A8E;
  text-align: left;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.text {
  margin-top: 20px;
}

.actions {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  padding: 0 16px 16px;

  .actionButton {
    border-radius: 12px !important;
    height: 50px;
    width: 120px;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
  }

  @media screen and (max-width: 300px) {
    .actionButton {
      height: 40px;
      width: 80px;
    }
  }

  .actionButton:first-child {
    border: 2px solid #5B84BA !important;
    color: #5B84BA !important;
  }

  .actionButton:last-child {
    background-color: #5B84BA !important;
  }

  .placeholder {
    width: 50%;
    height: 12px;
  }
}

.dialog {
  div[aria-describedby="alert-dialog-description"] {
    border-radius: 15px;
    max-height: 277px;
    max-width: 303px;
    margin-top: 50px;
  }
}
