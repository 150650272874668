.sagButton {
  background-color: #ad206e !important;
  border-radius: 100px !important;
  color: white !important;
}

.sagOutlinedButton {
  background-color: unset;
  border: solid 1px #ad206e !important;
  border-radius: 100px !important;
  color: #ad206e !important;
}
