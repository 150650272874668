.side-bar-item {
  align-items: center;
  color: #535358;
  cursor: pointer;
  display: flex;
  font-family: 'Nunito Sans', sans-serif;
  height: 60px;
  justify-content: space-between;
  width: 100%;

  .side-bar-item-icon,
  .side-bar-item-icon-right {
    height: 26px;
    margin-left: 16px;
    margin-right: 8px;
    width: 26px;
  }

  .side-bar-item-left {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: bold;
  }

  .side-bar-item-icon-right {
    color: #5B84BA !important;
    height: 16px;
    margin-right: 16px;
    width: 16px;
  }

  .side-bar-item-notification {
    align-items: center;
    background-color: #5B84BA;
    border-radius: 100px;
    color: white;
    display: flex;
    font-size: 13px;
    font-weight: normal;
    justify-content: center;
    margin-left: 8px;
    min-height: 22px;
    min-width: 22px;
  }

  &:hover {
    background-color: rgb(248 248 248);
  }
}
