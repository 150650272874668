.actions {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .actionButton {
    background: #f0991f !important;
    color: white !important;
  }

  .countDisplay {
    font-size: 12px;
    color: #767A8E;
  }

  .button {
    min-width: 20px;
    color: #5B84BA;
    font-size: 32px;
  }
}
