.dialog {
  div[aria-describedby="alert-dialog-description"] {
    border-radius: 15px;
    max-height: 277px;
    max-width: 303px;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    font-family: 'Poppins';
  }

  .dialogContent {
    display: flex;
    justify-content: center;
    .dialogContentText {
      font-size: 14px;
      font-weight: 700;
      font-family: 'Poppins';
      color: #5B84BA;
    }
  }
}

