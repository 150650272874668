.listContainer {
  width: 100%;
  height: 80%;
  overflow: auto;
  margin-top: 9px;
}
.listItemWrapper {
  height: 45px;
}

.listItem {
  color: #767a8e;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  justify-content: space-between;
  padding: 0 15px;
  width: calc(100% - 26px);

  .itemName {
    text-align: left;
    width: 45%;
  }

  .itemCount {
    width: 25%;
  }

  .itemPrice {
    width: 15%;
  }

  .itemPricePant {
    color: #767a8e;
    font-size: smaller;
    text-align: start;
  }
}

.expandedRow {
  display: flex;
  padding: 0 0 13px 13px;
  width: calc(100% - 26px);

  .actions {
    display: flex;
    justify-content: flex-end;
    width: 16%;
    margin-left: 4px;

    .actionButton {
      background: #f0991f !important;
      color: white !important;
    }
  }

  .countControl {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 16px;
    width: 75px;
    height: 14px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
  }

  .placeholder {
    width: 50%;
  }
}

.divider {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.accordion {
  box-shadow: none !important;
}
