.splashContainer {
  $verticalGap: 24px;

  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  padding: 15%;

  .logo {
    height: 116px;
    margin-bottom: $verticalGap;
    width: 127px;
  }

  .title {
    color: #5B84BA;
    font-family: 'Poppins', 'sans-serif';
    font-size: 16px;
    font-weight: bold;
  }

  .accessButton {
    border: 1px solid #5B84BA;
    border-radius: 15px !important;
    padding: 16px;
    color: #5B84BA !important;
    background-color: #fff !important;
    font-family: 'Poppins', 'sans-serif';
    text-transform: none;
  }
}

