.sidebar {
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Sans', sans-serif;
  height: 100%;
  left: 0;
  overflow: hidden;
  top: 0;
  width: 300px;

  .sidebar-items {
    height: calc(100vh - 244px);
    overflow-y: auto;
    padding-top: 24px;
  }
}

.sidebar-header-container {
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 75%);
  height: 220px;
  width: 100%;
}

.sidebar-subheader {
  background-color: #5B84BA;
  color: white;
  height: 170px;
  line-height: 1.6rem;
  text-align: left;
  width: 100%;
  padding: 20px 0 0;

  .sidebar-subheader-title {
    font-size: 18px;
    font-weight: bold;
    margin-left: 16px;
  }

  .sidebar-subheader-body {
    font-size: 16px;
    margin-left: 16px;
  }

  .sidebar-subheader-shop {
    align-items: center;
    color: #f0991f;
    display: flex;
  }

  .sidebar-subheader-shop > *:first-child {
    margin-right: 8px;
  }

  .sidebar-subheader-logout {
    font-size: 16px;
    font-weight: bold;
    margin-left: 16px;

    a {
      color: white;
    }
  }
}
