.netAxeptContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  .cardLinked {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    .checkIcon {
      animation: pulseAnim 500ms ease-in-out;
    }

    .title {
      font-size: 1.4rem;
    }
  }
}

@keyframes pulseAnim {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
