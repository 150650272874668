.newsList {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 24px 0 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .newsItem {
    border-radius: 5px;
    box-shadow: 0 6px 6px 0 rgb(0 0 0 / 16%);
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    max-width: 700px;
    text-align: left;
    width: 90%;
    position: relative;

    .readBox {
      align-self: flex-end;
      border: 1px solid #5B84BA;
      border-radius: 20px;
      background-color: #5B84BA;
      color: #fff;
      font-family: Poppins;
      font-size: 12px;
      padding: 4px 12px;
      position: absolute;
      transform: translate(10%, -20%);
    }

    .newsItemHeader {
      background-color: #eee;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      height: 30%;
      padding: 8px;
      width: calc(100% - 16px);

      .newsItemDate {
        color: #767A8E;
        font-size: 12px;
      }

      .newsItemTitle {
        color: #5B84BA;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .newsItemContent {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      height: 70%;
      padding: 8px;
      width: calc(100% - 16px);

      .newsItemMessage {
        -webkit-box-orient: vertical;
        display: flex;
        flex-direction: column;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #767A8E;
      }

      .newsItemMoreBtn {
        align-self: flex-end;
        text-align: right;
        font-size: 12px;
        color: #5B84BA;
      }
    }
  }
}
