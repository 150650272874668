.scannerWrapper {
  background-color: #fff;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
}
.scannerContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.scannerSection {
  height: 200px;
  width: 100%;
}

.listSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 15px;
  overflow-y: auto;
}

.clearBasket {
  position: absolute;
  top: 90%;
  height: 10%;
  transform: translateY(-100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 94%;
  .clearBasketButton {
    min-width: 20px;
    color: #5B84BA;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 5px;
    border-radius: 8px;
    font-family: 'Poppins';
  }
}

.cartButton {
  font-weight: bold !important;
  justify-content: space-between !important;
  left: 100%;
  position: absolute !important;
  top: 100%;
  transform: translate(-110%, -125%);
  width: 180px !important;
}

.spinner {
  margin-top: 40vh;
}