.header {
  align-items: center;
  background-color: #5B84BA;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 50;
  padding: 5px 0;

  .imageWrapper{
    width: 30%;
    display: flex;
  }
  .headerLogo {
    height: 40px;
    margin-left: 16px;
    width: 40px;
  }

  .devSupport {
    opacity: 0;
    width: 25%;
    height: 100%;
  }

  .title {
    color: white;
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    align: center;
    width: 20%;
  }
}
