.sidebarContentContainer {
  width: 300px;

  .sidebarContent {
    z-index: 100000;
  }
}

.innerContent {
  height: calc(100% - 120px);
  & > div {
    height: 100%;
  }
  background-color: #fff;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
}

.innerContentNoFooter {
  @extend .innerContent;
  height: 100%;
}

.footer {
  background-color: #cecece;
  transform: translateY(-100%);
}

.drawerPaper {
  height: 100% !important;
  overflow-y: hidden !important;
}

.browserWrapper {
  background-image: url("../../assets/browser_cover.png");
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .browserMessageBox {
    width: 400px;
    height: 150px;
    margin: auto;
    background-color: lightgray;
    opacity: .8;
    padding: 30px 60px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .browserMessageWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .browserIcon {
        svg {
          color: orange;
          font-size: 2.3rem;
        }
      }
    }
  }
  .browserMessageAction {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    button {
        width: 120px;
        height: 60px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #5B84BA;
        color: #5B84BA;
        font-weight: bold;
        font-size: 1rem;
        cursor: pointer;
        margin-top: 10px;
        font-family: 'Poppins';
        &:hover {
            background-color: #cecece;
        }
    }
  }
}
