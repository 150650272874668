.dialog {
  div[aria-describedby="alert-dialog-description"] {
    border-radius: 15px;
    max-height: 277px;
    max-width: 303px;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    font-family: 'Poppins';
  }

  .textField {
    margin-top: 20px;
    font-family: 'Poppins';
  }

  fieldset {
    border-radius: 8px !important;
    border-color: #949494 !important;
  }
  label[data-shrink="true"] {
    color: #999999 !important;
    font-family: 'Poppins';
  }

  .actions {
    display: flex;
    justify-content: flex-start;
    padding: 0 16px 16px;

    .actionButton {
      border-radius: 12px !important;
      height: 50px;
      width: 120px;
      font-size: 14px;
      font-style: normal;
      font-weight: 100;
      text-transform: none;
      font-family: 'Poppins';
    }

    @media screen and (max-width: 300px) {
      .actionButton {
        height: 40px;
        width: 80px;
        font-size: 10px;
      }
    }

    .actionButton:first-child {
      border: 2px solid #5B84BA !important;
      color: #5B84BA !important;
      margin-right: 20px;
    }

    .actionButton:last-child {
      background-color: #5B84BA !important;
      color: #fff;
    }
  }
}
