.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  height: 100%;
  width: 100%;

  .status {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 23px 0 40px;
    .placeholder {
      width: 25%;
    }
    .statusText {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #333;
      width: 50%;
    }
    .closeButton {
      color: #f0991f;
      position: absolute;
      right: 13px;
      border: 1px solid #E6E6E6;
      border-radius: 8px;
      padding: 10px 10px 6px;
      margin: 13px 13px 0 0;
      svg {
        color: #5B84BA;
      }
    }
  }
  .qrCode {
    align-items: center;
    background: #E6E6E6;;
    border-radius: 30px;
    display: flex;
    width: 214px;
    height: 214px;
    justify-content: center;
    padding: 16px;
  }

  .infoText {
    margin-top: 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #767A8E;
  }

  .infoTextTime {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #5B84BA;
    margin-top: 5px;
  }
}
