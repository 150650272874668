.splashContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 16px 0 32px;

  .tip {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    width: 200px;

    .tipTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #333333;
    }

    .tipSubTitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #767a8e;
    }
  }



  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .descriptionTitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #333333;
    }

    .descriptionSubTitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #767a8e;
    }
  }

  .tip2 {
    .title {
      font-size: 12px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      color: #333;
    }
    .subTitle {
      @extend .title;
      color: #767a8e;
      font-size: 12px;
    }
  }
}
