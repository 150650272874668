.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-weight: bold;
  height: 100%;
  width: 100%;

  .status {
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    .placeholder {
      width: 30%;
    }
    .statusText {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #333;
      width: 40%;
    }
    .closeButton {
      color: #f0991f;
      position: absolute;
      right: 13px;
      border: 1px solid #E6E6E6;
      border-radius: 8px;
      padding: 10px 10px 6px;
      margin: 13px 13px 0 0;
      svg {
        color: #5B84BA;
      }
    }
  }

  .text {
    height: 50%;
    .infoText {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #767A8E;
    }

    .statusText {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #333333;
      margin-top: 22px;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 25px;

    button {
      border-radius: 8px !important;
      margin-bottom: 15px;
      height: 50px;
      text-transform: none !important;
      font-family: 'Poppins' !important;
    }
    button:first-child {
      background: #5B84BA !important;
      color: #fff !important;
    }
    button:last-child {
      color: #5B84BA !important;
      border: 1px solid #5B84BA !important;
    }
  }
}
