.app {
  font-family:
    'Poppins',
    'Nunito Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  height: 100%;
  text-align: center;
}

.appLogo {
  height: 40vmin;
  pointer-events: none;
}

.appContent {
  height: 100%;
  max-width: 100%;
  padding: 0 10px;
  background-color: #5B84BA;
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 600px) {
    width: 400px;
    margin: auto;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .appLogo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.appHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  // background-color: #282c34;
  min-height: 100vh;
}

.appLink {
  color: #61dafb;
}

@keyframes appLogoSpin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.scandit-barcode-picker {
  max-height: 100% !important;
  max-width: 95% !important;
  margin: 9px !important;
  background: rgba(113, 113, 113, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 15px 15px 0 0;
  overflow: hidden;
}

.scandit-video {
  height: 197px !important;
}

.scandit .scandit-logo {
  top: 0;
}

.scandit .scandit-torch-toggle {
  top: 15%;
  right: 5%;
}
