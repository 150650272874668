.dialogPaper {
  //background-color: unset !important;
}

.container {
  div[role="dialog"] {
    height: 100%;
    width: 100%;
    @media screen and(min-width: 600px) {
      width: 420px;
    }
  }
  label {
    font-size: 14px;
  }
  div:first-child {
    margin: 0;
    max-height: 100%;

  }
}

.header {
  .closeButton {
    left: 100%;
    transform: translateX(-100%);
  }
}

.dialogMainContent {
  height: 93vh;
  overflow-y: auto;

  .searchField {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 24px;
    width: 100%;
  }

  .shopPlatesContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 24px;

    .shopPlate {
      font-family: 'Poppins';
      animation: slideIn 200ms ease-in;
      background-color: #5B84BA;
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
      height: 45px;
      margin-bottom: 24px;
      opacity: 0.95;
      padding: 16px;
      width: calc(90% - 48px);

      .name {
        font-weight: bold;
      }

      .location {
      }
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 0.95;
    transform: translateY(0%);
  }
}
