.viewContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  .status {
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-top: 10px;
    .placeholder {
      width: 30%;
    }
    .statusText {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #333;
      width: 40%;
    }
    .closeButton {
      color: #f0991f;
      position: absolute;
      right: 13px;
      border: 1px solid #E6E6E6;
      border-radius: 8px;
      padding: 10px 10px 6px;
      margin: 13px 13px 0 0;
      svg {
        color: #5B84BA;
      }
    }
  }
  .receipt {
    max-width: 400px;
    height: 100%;
    padding: 24px;
    width: calc(100% - 24px);
    overflow-y: auto;

    img {
      height: 105px;
      margin-bottom: 24px;
      width: 113px;
    }

    .receiptInfo {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      margin-bottom: 24px;
      width: 100%;
      span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #333333;
      }
    }

    .receiptItems {
      .listItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Poppins', 'Nunito Sans', sans-serif;
        height: 45px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #767A8E;
        padding: 0 8px;

        .itemName {
          text-align: left;
          width: 40%;
        }

        .itemCount {
          width: 20%;
        }

        .itemPricePantFormat {
          align-self: flex-end !important;
        }

        .itemPrice {
          align-self: center;
          line-height: 8px;
          text-align: right;
          width: 20%;
        }

        .itemPricePant {
          align-self: flex-end;
          font-weight: initial;
        }
      }
    }

    .receiptPrice {
      align-items: flex-end;
      color: #767A8E;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      margin: 16px 0;
      width: 98%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      & > * {
        display: flex;
        justify-content: space-between;
        width: 150px;
      }

      .receiptPriceTotal {
        font-weight: 500;
        color: #333333;
      }
      hr {
        margin-top: 12px;
      }
    }
    .transactionInfo {
      align-items: flex-end;
      color: #767A8E;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      width: 98%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      .transactionInfoTitle {
        @extend .transactionInfo;
        font-weight: 500;
        color: #333333;
        margin: 0;
      }
    }
  }
}


