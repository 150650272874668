.barcodeContainer {
  height: 200px;
  width: 100%;
  z-index: 10;
  position: relative;
}

.barcodeWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: center;
  position: absolute;
  transform: translate(0, -100%);
  width: inherit;

  .searchArea {
    border: dashed 2px white;
    border-radius: 15px;
    height: 100%;
    margin: 6px;
    opacity: 0.7;
    width: 90%;
  }

  .manuallyEnteredButton {
    background-color: #5B84BA !important;
    border-radius: 0 0 8px 8px !important;
    color: #FFFFFF !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    justify-self: flex-end !important;
    padding: 8px !important;
    width: 95%;
    height: 40px;
    .img {
      margin-right: 10px;
    }
  }
  .infoButton {
    position: absolute;
    left: -5px;
    bottom: 35px;
    z-index: 100;
    .infoIcon{
      font-size: 2rem;
    }
  }
}

