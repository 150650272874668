.dialogTitle {
  background-color: #eee !important;

  .dialogTitleDate {
    font-size: 12px;
  }

  .dialogTitleText {
    font-size: 14px;
    font-weight: bold;
  }
}

.dialogContentText {
    font-size: 12px !important;
    color: #767A8E !important;
    font-family: 'Poppins' !important;
}

.actions {
  display: flex !important;
  justify-content: center !important;

  .closeBtn {
    width: 50%;
    border-radius: 8px !important;
    background-color: #5B84BA !important;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

div[role="dialog"] {
  @media screen and(min-width: 600px){
    width: 420px;
  }
}
