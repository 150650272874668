.listWrapper {
  display:inline-block;
  text-align: left;
}


.listItem {
  font-size: .8rem;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  display: flex;
  flex-direction: column;
}
.selectedItem  {
  @extend .listItem;
  background-color: #3788c3;
  color: white;
  border-radius: 1.4rem;
}

.listItemInner {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.barcodeImage {
  height: 3rem;
}

