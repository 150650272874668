.cardsContainer {
  padding-top: 24px;

  .addCardButton {
    max-width: 200px;
    width: 80%;
    border-radius: 8px !important;
    background-color: #5B84BA !important;
    font-family: 'Poppins';
  }
}
