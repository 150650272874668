.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card {
  align-items: center;
  background-color: #eee;
  border-radius: 5px;
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin-bottom: 16px;
  max-width: 500px;
  padding: 0 16px;
  width: 80%;

  .cardImage {
    background-color: #0353a5;
    border-radius: 5px;
    height: 50px;
    width: 70px;
  }

  .info {
    color: #333c6b;
    font-weight: bold;
    text-align: left;

    .title {
      font-size: 16px;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .cardNumber {
      font-size: 12px;
    }
  }

  .actions {
    display: flex;

    * {
      width: 30px;
    }
  }
}
