.footer {
  align-items: center;
  display: flex;
  height: 73px;
  justify-content: flex-start;
  z-index: 50;
  position: absolute;
  bottom: -72px;
  right: 0;
  left: 0;
  background-color: #e6e6e6;
}

.footerLogo {
  height: 40px;
  margin-left: 16px;
  width: 40px;
}

.hamburger {
  align-items: center;
  align-self: center;
  border-radius: 50px;
  color: #767a8e;
  cursor: pointer;
  display: flex;
  font-size: 30px;
  height: 40px;
  justify-content: center;
  margin-left: 8px;
  user-select: none;
  width: 40px;
}

.content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  width: 100%;

  .contentText {
    text-align: left;

    .contentTitle {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
    }

    .contentSubTitle {
      font-size: 9px;
      color: #767a8e;
    }
  }
}

.cartButtonWrapper {
  margin-right: 9px;
  .cartButton {
    width: 120px;
    height: 40px;
    border-radius: 8px !important;
    background: #5b84ba !important;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    @media screen and (max-width: 330px) {
      width: 100px;
      height: 30px;
      font-size: 12px;
    }
  }
}
