.receiptsListPaper {
  padding: 0 0 50px 0;

  .listContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .receipt {
      background-color: #f1f1f1;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      margin-bottom: 16px;
      max-width: 500px;
      padding: 8px;
      width: 90%;

      .receiptInfo {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        & > * {
          width: 50%;
        }

        & > *:first-child {
          font-weight: bold;
        }
      }

      .receiptPrice {
        display: flex;
        justify-content: space-between;

        .receiptPriceNumber {
          font-weight: bold;
        }
      }
    }
  }
}